const ROLE_EMPLOYEE = 'Employee';
const ROLE_ADMIN = 'Admin';
const ROLE_SALES = 'Sales';
const ROLE_COMPLIANCE_TEAM = 'Compliance Team';
const ROLES = [ROLE_SALES, ROLE_COMPLIANCE_TEAM, ROLE_ADMIN];

const canAccess = (role, allowedRoles) => {
  if (!allowedRoles || !role) return false;
  if (allowedRoles === '*') return true;
  return typeof allowedRoles === 'object' ? allowedRoles.includes(role) : false;
};

export { ROLES, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_SALES, ROLE_COMPLIANCE_TEAM, canAccess };