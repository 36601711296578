import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { PER_PAGE_SKU } from "../../../constants/pagination";
import { prepareHeaders } from "../../auth/slice/AuthSlice";
import { handleApiError } from "../../common/utils/ErrorHandler";
import { CD_STATUS_COMPLETED, CD_STATUS_IN_PROGRESS, CD_STATUS_NEW } from "../../../constants/sku";

const skuUrl = 'api/admin/sku';

const fetchSkus = async ({ customer_id = '', page = 1, code = '', filter_status = '', perPage = PER_PAGE_SKU }, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${skuUrl}/listing-define-sku`, {
      params: { page, per_page: perPage, reference_number: code, customer_id, status: filter_status },
      headers: prepareHeaders(state)
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
};

export const fetchNewSkus = createAsyncThunk('catDefineSkus/fetchNewSkus', (arg, { getState, rejectWithValue, dispatch }) => fetchSkus({ filter_status: CD_STATUS_NEW, ...arg }, { getState, rejectWithValue, dispatch }));

export const fetchProgressSkus = createAsyncThunk('catDefineSkus/fetchProgressSkus', (arg, { getState, rejectWithValue, dispatch }) => fetchSkus({ filter_status: CD_STATUS_IN_PROGRESS, ...arg }, { getState, rejectWithValue, dispatch }));

export const fetchCompletedSkus = createAsyncThunk('catDefineSkus/fetchCompletedSkus', (arg, { getState, rejectWithValue, dispatch }) => fetchSkus({ filter_status: CD_STATUS_COMPLETED, ...arg }, { getState, rejectWithValue, dispatch }));

export const fetchCatDefineRequest = createAsyncThunk('catDefineSkus/fetchCatDefineRequest', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${skuUrl}/detail-sku-with-define-question-answer/${id}`, {
      headers: prepareHeaders(state)
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

export const saveSkuDefineCategory = createAsyncThunk('catDefineSkus/saveSkuDefineCategory', async (data, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.post(`${skuUrl}/store-define-category`, data, {
      headers: prepareHeaders(state)
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

export const saveSkuQuestion = createAsyncThunk('catDefineSkus/saveSkuQuestion', async (data, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.post(`${skuUrl}/store-sku-question`, data, {
      headers: prepareHeaders(state)
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

const CatDefineSkuSlice = createSlice({
  name: 'catSku',
  initialState: {
    newRequestLoading: false,
    newRequests: [],
    newRequestFilter: '',
    newRequestPerPage: PER_PAGE_SKU,
    newRequestPageCount: 0,
    newRequestCurrentPage: 0,
    inProgressLoading: false,
    inProgressRequests: [],
    inProgressFilter: '',
    inProgressPerPage: PER_PAGE_SKU,
    inProgressPageCount: 0,
    inProgressCurrentPage: 0,
    completedLoading: false,
    completedRequests: [],
    completedFilter: '',
    completedPerPage: PER_PAGE_SKU,
    completedPageCount: 0,
    completedCurrentPage: 0,
    detail: null,
    isDetailLoading: false,
    newRequestError: '',
    inProgressError: '',
    completedError: '',
    isDataSaving: false,
    validationError: []
  },
  reducers: {
    setDetail(state, action) {
      state.detail = action.payload;
    },
    setNewRequestFilter(state, action) {
      state.newRequestFilter = action.payload;
      state.newRequestCurrentPage = 0;
    },
    setNewRequestPerPage(state, action) {
      state.newRequestPerPage = action.payload;
    },
    setNewRequestPage(state, action) {
      state.newRequestCurrentPage = action.payload;
    },
    setInProgressFilter(state, action) {
      state.inProgressFilter = action.payload;
      state.inProgressCurrentPage = 0;
    },
    setInProgressPerPage(state, action) {
      state.inProgressPerPage = action.payload;
    },
    setInProgressPage(state, action) {
      state.inProgressCurrentPage = action.payload;
    },
    setCompletedFilter(state, action) {
      state.completedFilter = action.payload;
      state.completedCurrentPage = 0;
    },
    setCompletedPerPage(state, action) {
      state.completedPerPage = action.payload;
    },
    setCompletedPage(state, action) {
      state.completedCurrentPage = action.payload;
    },
    clearValidationError(state) {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewSkus.pending, (state) => {
        state.newRequestLoading = true;
        state.newRequestError = '';
      })
      .addCase(fetchNewSkus.fulfilled, (state, action) => {
        state.newRequestLoading = false;
        const payload = action.payload?.payload;
        state.newRequests = payload?.data;
        state.newRequestPageCount = payload?.last_page ?? 0;
        state.newRequestCurrentPage = payload?.current_page ? payload.current_page - 1 : 0;
      })
      .addCase(fetchNewSkus.rejected, (state, action) => {
        state.inProgressLoading = false;
        state.inProgressError = action.payload;
      })
      .addCase(fetchProgressSkus.pending, (state) => {
        state.inProgressLoading = true;
        state.inProgressError = '';
      })
      .addCase(fetchProgressSkus.fulfilled, (state, action) => {
        state.inProgressLoading = false;
        const payload = action.payload?.payload;
        state.inProgressRequests = payload?.data;
        state.inProgressPageCount = payload?.last_page ?? 0;
        state.inProgressCurrentPage = payload?.current_page ? payload.current_page - 1 : 0;
      })
      .addCase(fetchProgressSkus.rejected, (state, action) => {
        state.inProgressLoading = false;
        state.inProgressError = action.payload;
      })
      .addCase(fetchCompletedSkus.pending, (state) => {
        state.completedLoading = true;
        state.completedError = '';
      })
      .addCase(fetchCompletedSkus.fulfilled, (state, action) => {
        state.completedLoading = false;
        const payload = action.payload?.payload;
        state.completedRequests = payload?.data;
        state.completedPageCount = payload?.last_page ?? 0;
        state.completedCurrentPage = payload?.current_page ? payload.current_page - 1 : 0;
      })
      .addCase(fetchCompletedSkus.rejected, (state, action) => {
        state.completedLoading = false;
        state.completedError = action.payload;
      })
      .addCase(fetchCatDefineRequest.pending, (state) => {
        state.isDetailLoading = true;
        state.completedError = '';
      })
      .addCase(fetchCatDefineRequest.fulfilled, (state, action) => {
        state.isDetailLoading = false;
        state.detail = action.payload?.payload;
      })
      .addCase(fetchCatDefineRequest.rejected, (state, action) => {
        state.isDetailLoading = false;
        state.completedError = action.payload;
      })
      .addCase(saveSkuDefineCategory.pending, (state) => {
        state.isDataSaving = true;
        state.validationError = [];
      })
      .addCase(saveSkuDefineCategory.fulfilled, (state, action) => {
        state.isDataSaving = false;
        state.detail = action.payload?.payload;
      })
      .addCase(saveSkuDefineCategory.rejected, (state, action) => {
        state.isDataSaving = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      .addCase(saveSkuQuestion.pending, (state) => {
        state.isDataSaving = true;
        state.validationError = [];
      })
      .addCase(saveSkuQuestion.fulfilled, (state) => {
        state.isDataSaving = false;
      })
      .addCase(saveSkuQuestion.rejected, (state, action) => {
        state.isDataSaving = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
  }
});

export const { setDetail, setNewRequestFilter, setNewRequestPerPage, setNewRequestPage, setInProgressFilter, setInProgressPerPage, setInProgressPage, setCompletedFilter, setCompletedPerPage, setCompletedPage, clearValidationError } = CatDefineSkuSlice.actions;

export default CatDefineSkuSlice.reducer;