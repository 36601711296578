export const AVAILABLE = 'Available';
export const IN_PROGRESS = 'In Progress';
export const REQUESTED = 'Requested';
export const IN_REVIEW = 'In Review';
export const ADDITIONAL_INFO = 'Additional Info';
export const FORWARDED = 'Forwarded';
export const CANCELED = 'Canceled';
export const NOT_RESPONDED = 'Not Responded';
export const ADDITIONAL_INFO_SUBMITTED = 'Additional Info Submitted';
export const FINALIZED = 'Finalized';

export const SKU_STATUS = [IN_PROGRESS, REQUESTED, IN_REVIEW, ADDITIONAL_INFO, FORWARDED, CANCELED, NOT_RESPONDED, ADDITIONAL_INFO_SUBMITTED, FINALIZED];

// review status
export const REVIEW_STATUS_OPEN = "Open";
export const REVIEW_STATUS_PICKED = "Picked";
export const REVIEW_STATUS_COMPLETED = "Completed";

// payment
export const PAID = 'Paid';
export const UNPAID = 'Unpaid';
export const DECLINED = 'Declined';

export const PAYMENT_STATUS = [PAID, UNPAID, DECLINED];

// category define status
export const CD_STATUS_DRAFT = 'Draft';
export const CD_STATUS_NEW = 'New Request';
export const CD_STATUS_IN_PROGRESS = 'In Progress';
export const CD_STATUS_COMPLETED = 'Finalized';

export const CD_STATUS = [CD_STATUS_DRAFT, CD_STATUS_NEW, CD_STATUS_IN_PROGRESS, CD_STATUS_COMPLETED];

// sales status 
export const SALES_NEW_INQUIRY = 'New Inquiry';
export const SALES_COMPLETED = 'Completed';