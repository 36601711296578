import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';

const dashboardUrl = 'api/admin/dashboard';

export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchDashboardData',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${dashboardUrl}/home-page`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchComplianceCheckTimeSeries = createAsyncThunk(
  'dashboard/fetchComplianceCheckTimeSeries',
  async ({ filter }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${dashboardUrl}/time-series-graph`, {
        params: { filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchSKUTimeSeries = createAsyncThunk(
  'dashboard/fetchSKUTimeSeries',
  async ({ filter }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${dashboardUrl}/sku-revenue`, {
        params: { filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const sendReferralMail = createAsyncThunk(
  'dashboard/sendReferralMail',
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`api/admin/referral/mail`, data, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  });


const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: false,
    complianceLoading: false,
    skuLoading: false,
    sendingMail: false,
    dashboardData: [],
    complianceData: [],
    skuData: [],
    filter: 'daily',
    error: '',
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch data for dashboard
      .addCase(fetchDashboardData.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardData = action.payload?.payload;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch data for compliance check
      .addCase(fetchComplianceCheckTimeSeries.pending, (state) => {
        state.complianceLoading = true;
        state.error = '';
      })
      .addCase(fetchComplianceCheckTimeSeries.fulfilled, (state, action) => {
        state.complianceLoading = false;
        state.complianceData = action.payload?.payload;
      })
      .addCase(fetchComplianceCheckTimeSeries.rejected, (state, action) => {
        state.complianceLoading = false;
        state.error = action.payload;
      })
      // fetch data for sku
      .addCase(fetchSKUTimeSeries.pending, (state) => {
        state.skuLoading = true;
        state.error = '';
      })
      .addCase(fetchSKUTimeSeries.fulfilled, (state, action) => {
        state.skuLoading = false;
        state.skuData = action.payload?.payload;
      })
      .addCase(fetchSKUTimeSeries.rejected, (state, action) => {
        state.skuLoading = false;
        state.error = action.payload;
      })
      // send referral mail
      .addCase(sendReferralMail.pending, (state) => {
        state.sendingMail = true;
        state.validationError = [];
      })
      .addCase(sendReferralMail.fulfilled, (state, action) => {
        state.sendingMail = false;
        state.validationError = [];
        state.skuData = action.payload?.payload;
      })
      .addCase(sendReferralMail.rejected, (state, action) => {
        state.sendingMail = false;
        if (typeof action.payload != 'string') {
          state.validationError = action.payload?.errors;
        } else {
          state.error = action.payload;
        }
      });
  }
});

export const { setFilter, clearError } = DashboardSlice.actions;

export default DashboardSlice.reducer;