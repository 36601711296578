import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { SALES_COMPLETED, SALES_NEW_INQUIRY } from "../../../constants/sku";
import { PER_PAGE_SKU } from "../../../constants/pagination";
import { prepareHeaders } from "../../auth/slice/AuthSlice";
import { handleApiError } from "../../common/utils/ErrorHandler";
import axiosInstance from "../../../app/axiosInstance";

const salesListUrl = 'api/admin/sales';

const fetchSalesInquiry = async ({ page = 1, search = '', sales_status = '', perPage = PER_PAGE_SKU }, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${salesListUrl}/index`, {
      params: { page, per_page: perPage, search, sales_status },
      headers: prepareHeaders(state)
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
};
// fetch new sales inquiries
export const fetchNewSalesInquiry = createAsyncThunk('salesInquiries/fetchNewSalesInquiry', (arg, { getState, rejectWithValue, dispatch }) => fetchSalesInquiry({ sales_status: SALES_NEW_INQUIRY, ...arg }, { getState, rejectWithValue, dispatch }));
// fetch completed sales inquiries
export const fetchCompletedSalesInquiry = createAsyncThunk('salesInquiries/fetchCompletedSalesInquiry', (arg, { getState, rejectWithValue, dispatch }) => fetchSalesInquiry({ sales_status: SALES_COMPLETED, ...arg }, { getState, rejectWithValue, dispatch }));

export const confirmSalesInquiryAndContact = createAsyncThunk('salesInquiries/confirmSalesInquiryAndContact', async ({ id, data }, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.post(`${salesListUrl}/confirm-n-contact/${id}`, data, {
      headers: prepareHeaders(state)
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

const SalesRequestSlice = createSlice({
  name: 'salesSku',
  initialState: {
    salesList: [],
    loading: false,
    error: null,
    search: '',
    pageCount: 0,
    currentPage: 0,
    perPage: PER_PAGE_SKU,
    completedSalesList: [],
    completedLoading: false,
    completedError: null,
    completedSearch: '',
    completedPageCount: 0,
    completedCurrentPage: 0,
    completedPerPage: PER_PAGE_SKU,
    confirmLoading: false,
    confirmError: null,
    validationError: []
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    setCompletedPage: (state, action) => {
      state.completedCurrentPage = action.payload;
    },
    setCompletedPerPage: (state, action) => {
      state.completedPerPage = action.payload;
      state.completedCurrentPage = 0;
    },
    setCompletedSearch: (state, action) => {
      state.completedSearch = action.payload;
      state.completedCurrentPage = 0;
    },
    clearCompletedError: (state) => {
      state.completedError = '';
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch new sales inquiry
      .addCase(fetchNewSalesInquiry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNewSalesInquiry.fulfilled, (state, action) => {
        state.loading = false;
        state.salesList = action.payload?.payload?.data;
      })
      .addCase(fetchNewSalesInquiry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch completed sales inquiry
      .addCase(fetchCompletedSalesInquiry.pending, (state) => {
        state.completedLoading = true;
        state.completedError = null;
      })
      .addCase(fetchCompletedSalesInquiry.fulfilled, (state, action) => {
        state.completedLoading = false;
        state.completedError = null;
        state.completedSalesList = action.payload?.payload?.data;
      })
      .addCase(fetchCompletedSalesInquiry.rejected, (state, action) => {
        state.completedLoading = false;
        state.completedError = action.payload;
      })
      // confirm and contact customer
      .addCase(confirmSalesInquiryAndContact.pending, (state) => {
        state.confirmLoading = true;
        state.confirmError = null;
      })
      .addCase(confirmSalesInquiryAndContact.fulfilled, (state) => {
        state.confirmLoading = false;
        state.confirmError = null;
      })
      .addCase(confirmSalesInquiryAndContact.rejected, (state, action) => {
        state.confirmLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        } else {
          state.confirmError = action.payload;
        }
      });
  }
})

export const { setPage, setPerPage, setSearch, clearError, setCompletedPage, setCompletedPerPage, setCompletedSearch, clearCompletedError } = SalesRequestSlice.actions;

export default SalesRequestSlice.reducer;