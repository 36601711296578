import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';
import { PER_PAGE_USER } from '../../../constants/pagination';
import { ROLE_COMPLIANCE_TEAM, ROLE_SALES } from '../../../constants/roles';

const userUrl = 'api/admin/user';

// fetch list of users
export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({ page = 1, filter = '', perPage = PER_PAGE_USER }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${userUrl}/index`, {
        params: { page, per_page: perPage, search: filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const fetchUserListByRole = async ({ role, search }, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${userUrl}/list`, {
      params: { role, search },
      headers: prepareHeaders(state)
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
};

export const fetchComplianceTeamUsers = createAsyncThunk('users/fetchComplianceTeamUsers', (arg, { getState, rejectWithValue, dispatch }) => fetchUserListByRole({ role: ROLE_COMPLIANCE_TEAM, ...arg }, { getState, rejectWithValue, dispatch }));

export const fetchSalesUsers = createAsyncThunk('users/fetchSalesUsers', (arg, { getState, rejectWithValue, dispatch }) => fetchUserListByRole({ role: ROLE_SALES, ...arg }, { getState, rejectWithValue, dispatch }));

// Async thunk for fetching user
export const fetchUser = createAsyncThunk('users/fetchUser', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${userUrl}/show/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for resetting user password
export const resetPassword = createAsyncThunk('users/resetPassword', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${userUrl}/auto-generate-password/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for creating user
export const createUser = createAsyncThunk(
  'users/createUser',
  async (newUser, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${userUrl}/store`, newUser, {
        headers: prepareHeaders(state, true, {
          'Content-Type': 'multipart/form-data',
        })
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
// Async thunk for updating user
export const updateUser = createAsyncThunk('users/updateUser', async (user, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    let userData = state.user.user;
    const response = await axiosInstance.post(`${userUrl}/update/${userData.id}`, user, {
      headers: prepareHeaders(state, true, {
        'Content-Type': 'multipart/form-data',
      }),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for deleting user
export const deleteUser = createAsyncThunk('users/deleteUser', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.delete(`${userUrl}/destroy/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    isModalLoading: false,
    isSalesLoading: false,
    isComplianceLoading: false,
    users: [],
    complianceUsers: [],
    salesUsers: [],
    user: null,
    pageCount: 0,
    currentPage: 0,
    filter: '',
    perPage: PER_PAGE_USER,
    error: '',
    complianceError: '',
    salesError: '',
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch compliance users
      .addCase(fetchComplianceTeamUsers.pending, (state) => {
        state.isComplianceLoading = true;
        state.complianceError = '';
      })
      .addCase(fetchComplianceTeamUsers.fulfilled, (state, action) => {
        state.isComplianceLoading = false;
        state.complianceUsers = action.payload?.payload ?? [];
      })
      .addCase(fetchComplianceTeamUsers.rejected, (state, action) => {
        state.isComplianceLoading = false;
        state.complianceError = action.payload;
      })
      // fetch sales users
      .addCase(fetchSalesUsers.pending, (state) => {
        state.isSalesLoading = true;
        state.salesError = '';
      })
      .addCase(fetchSalesUsers.fulfilled, (state, action) => {
        state.isSalesLoading = false;
        state.salesUsers = action.payload?.payload ?? [];
      })
      .addCase(fetchSalesUsers.rejected, (state, action) => {
        state.isSalesLoading = false;
        state.salesError = action.payload;
      })
      // create user
      .addCase(createUser.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isModalLoading = false;
        state.users.push(action.payload.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // Fetch user
      .addCase(fetchUser.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.user = data;
        state.isModalLoading = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // reset user password
      .addCase(resetPassword.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.user = data;
        state.isModalLoading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // update user
      .addCase(updateUser.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        let data = action.payload.payload;
        const index = state.users.findIndex(user => user.id === data.id);
        if (index !== -1) {
          state.users[index] = data;
        }
        state.user = data;
        state.isModalLoading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // delete user
      .addCase(deleteUser.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.user = null;
        state.isModalLoading = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      });
  }
});

export const { setFilter, setPage, setPerPage, clearError, clearValidationError } = userSlice.actions;

export default userSlice.reducer;