import moment from "moment";

import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATE_FORMAT } from "../../../constants/date";

export const dateFormat = (date, format) => {
    if (!date) return '';

    format = format ? format : DEFAULT_DATE_FORMAT;
    const dateObj = new Date(date);

    return moment(dateObj).format(format);
}

export const dateTimeFormat = (date, format) => {
    format = format || DEFAULT_DATETIME_FORMAT;
    return dateFormat(date, format);
}

export const getIdArrayFromDataArray = (dataArray) => dataArray?.map(item => item.id) ?? [];