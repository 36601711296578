import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';
import { PER_PAGE_CUSTOMER } from '../../../constants/pagination';

const customerUrl = 'api/admin/customer';

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async ({ page = 1, filter = '', perPage = PER_PAGE_CUSTOMER }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerUrl}/index`, {
        params: { page, per_page: perPage, search: filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchCustomerList = createAsyncThunk(
  'customers/fetchCustomerList',
  async (search, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerUrl}/list`, {
        params: { search },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const importCustomerCSVData = createAsyncThunk(
  'customers/importCustomerCSVData',
  async (csvData, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const formData = new FormData();
      formData.append('csv_file', csvData);

      const response = await axiosInstance.post(`${customerUrl}/process-csv-file`, formData, {
        headers: prepareHeaders(state, true, {
          'Content-Type': 'multipart/form-data',
        }),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for fetching customer
export const fetchCustomer = createAsyncThunk('customers/fetchCustomer', async (id, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${customerUrl}/show/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue);
  }
});

// Async thunk for creating customer
export const createCustomer = createAsyncThunk('customers/createCustomer', async (customer, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.post(`${customerUrl}/store`, customer, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for updating customer
export const updateCustomer = createAsyncThunk('customers/updateCustomer', async (customer, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    let customerData = state.customer.customer;
    const response = await axiosInstance.post(`${customerUrl}/update/${customerData.id}`, customer, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

const CustomerSlice = createSlice({
  name: 'customer',
  initialState: {
    loading: false,
    isModalLoading: false,
    customers: [],
    customer: null,
    customerList: [],
    pageCount: 0,
    currentPage: 0,
    filter: '',
    perPage: PER_PAGE_CUSTOMER,
    error: '',
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.error = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch customers
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch customer list
      .addCase(fetchCustomerList.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchCustomerList.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload.payload;
      })
      .addCase(fetchCustomerList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // import customer csv data
      .addCase(importCustomerCSVData.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(importCustomerCSVData.fulfilled, (state, action) => {
        state.isModalLoading = false;
      })
      .addCase(importCustomerCSVData.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload === 'object') {
          state.validationError = action.payload.errors;
        }
      })
      // Fetch customer
      .addCase(fetchCustomer.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.customer = data;
        state.isModalLoading = false;
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // create customer
      .addCase(createCustomer.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.customers.push(data);
        state.isModalLoading = false;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          const errors = action.payload.errors;
          let validationErrors = {};
          Object.keys(errors)?.forEach((key) => {
            let myKey = key.replace('customers.0.', '').trim();
            validationErrors[myKey] = errors[key];
          });
          state.validationError = validationErrors;
        }
      })
      // update customer
      .addCase(updateCustomer.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        let data = action.payload.payload;
        const index = state.customers.findIndex(customer => customer.id === data.id);
        if (index !== -1) {
          state.customers[index] = data;
        }
        state.customer = data;
        state.isModalLoading = false;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      });
  }
});

export const { setFilter, setPage, setPerPage, clearError, clearValidationError } = CustomerSlice.actions;

export default CustomerSlice.reducer;
